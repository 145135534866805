



















import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    return {
      userManager,
      orderFields: [
        { value: "revenue", text: "DSDK MKT" },
        { value: "realCod", text: "Tổng COD thu được" },
        { value: "baseCost", text: "Base cost" },
        { value: "baseCostPerRevenue", text: "%Base cost (VNĐ)/DSDK MKT" },
        { value: "profitWithoutMonthlyCostPerOrder", text: "LN chưa trừ CP cố định nhóm sản phẩm 1 đơn" },
        { value: "profitWithoutMonthlyCostPerRevenue", text: "LN chưa trừ CP cố định/DSDK MKT" },
        { value: "profit", text: "LN" },
      ],
    };
  },
});
